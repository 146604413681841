.signup-form .submitbtnsection button {
  background: var(--orange-gradient);
  color: var(--white);
  font-size: 2rem;
  text-transform: uppercase;
  min-width: 120px;
  margin: 2px;
  padding: 2rem 1rem;
  line-height: 2.5rem;
}

.signup-form .submitbtnsection button:first-of-type {
  margin-left: 0;
}

// .signup-form .grp1{ display: flex; flex-wrap: wrap; justify-content: space-between;}
// .signup_subwrapper  .grp1 .mat-mdc-form-field {
//     width: 100%;
// }

// .signup-form .grp1 .form_field_wrapper{ width: 32.5%; margin-top: 5px;}

.signup-form .grp0 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.signup-form .grp0 .form_field_wrapper {
  width: 49.5%;
  margin-top: 5px;
}

.signup-form .grp2 .form_field_wrapper {
  margin-top: 5px;
}

.signup-form .grp1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.signup-form .grp1 .form_field_wrapper {
  width: 32.5%;
  margin-top: 5px;
}

.signup_subwrapper .grp1 .mat-mdc-form-field {
  width: 100%;
  margin-bottom: 10px;
}

.signup-form .grp4 {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

.signup-form .grp4 .form_field_wrapper {
  margin: 5px 8px 0 0;
}

.signup_subwrapper .mat-mdc-form-field {
  width: 100%;
  margin-bottom: 20px;
}

.signup_subwrapper .mat-mdc-form-field .mat-mdc-form-field-subscript-wrapper,
.signup_subwrapper .mat-mdc-form-field .mdc-line-ripple {
  display: none;
}

.signup_subwrapper .mat-mdc-form-field .mat-mdc-text-field-wrapper {
  border-radius: 5px;
  background-color: #fdfdfd;
  border: 1px solid #c5c5c5;
  box-shadow: 1px 1px 3px #87878738;
  overflow: inherit;
}

.signup_subwrapper .mat-mdc-form-field .mat-mdc-form-field-infix input {
  font-size: 1.6rem;
}

.signup_subwrapper .mat-mdc-form-field .mat-mdc-form-field-infix {
  min-height: 45px !important;
  max-height: 60px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.signup_subwrapper .mat-mdc-form-field .mat-mdc-form-field-focus-overlay {
  display: none;
}

.signup_subwrapper
  .mat-mdc-text-field-wrapper
  .mat-mdc-form-field-flex
  .mat-mdc-floating-label {
  top: 22px;
}

.signup_subwrapper
  .mat-mdc-text-field-wrapper
  .mat-mdc-form-field-flex
  .mdc-floating-label--float-above {
  top: 7px !important;
  color: var(--orange-bottom-color);
  background: #fff;
  padding: 2px 9px 0 9px;
  border: 1px solid #c5c5c5;
  border-bottom: 0;
  border-radius: 4px 4px 0 0;
}

.signup_subwrapper .form_field_wrapper {
  position: relative;
}

.signup_subwrapper .form_field_wrapper .passbuttoncls {
  position: absolute;
  top: 12px;
  right: 10px;
}

.signup_subwrapper .mat-mdc-form-field-error {
  font-size: 12px;
}

.signup_subwrapper .passbuttoncls button {
  display: none;
}

.signup_subwrapper .form_field_password input,
.signup_subwrapper .form_field_confirmpassword input {
  padding-right: 25px;
  width: calc(100% - 25px);
}

.signup_subwrapper
  .mat-form-field-appearance-fill
  .mat-mdc-select-arrow-wrapper {
  transform: translateY(0px);
}

.signup-form .form_field_additional_info .mdc-text-field {
  height: 120px;
}

.signup-form .form_field_additional_info textarea {
  height: 100px !important;
}

.signup-form .form_field_address .mdc-text-field {
  height: 120px;
}

.signup-form .form_field_address textarea {
  height: 100px !important;
}

.addEditFormbooking .form {
  display: block;
}

.addEditFormbooking .grp0,
.addEditFormbooking .grp2,
.addEditFormbooking .grp4 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.addEditFormbooking .grp4 .form_field_wrapper {
  flex: 0 0 24%;
}

.addEditFormbooking .form_header_daygroup {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 0px;
  color: #fff;
}

// .form_field_description mat-label {
//   color: #7C8AA3;
// }

.addEditFormbooking .form_field_wrapperdaygroup {
  margin-bottom: 0px;
  display: inline-block;
}

.addEditFormbooking .form_field_wrappersun {
  display: inline-block;
}

.addEditFormbooking .form_field_wrappermon {
  display: inline-block;
}

.addEditFormbooking .form_field_wrappertues {
  display: inline-block;
}

.addEditFormbooking .form_field_wrapperwed {
  display: inline-block;
}

.addEditFormbooking .form_field_wrapperthurs {
  display: inline-block;
}

.addEditFormbooking .form_field_wrapperfri {
  display: inline-block;
}

.addEditFormbooking .form_field_wrappersat {
  display: inline-block;
}

.lstloging-block {
  align-items: center;
}

.connectedemail {
  color: #fff;
  text-transform: capitalize;
}

.connectedemail h2 {
  margin: 0 !important;
  padding: 0 !important;
  font-size: 22px;
  text-shadow: -3px -3px 7px #495569, 4px 4px 6px #0e1116;
}
.calenderinfo h4 {
  color: #fff;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  margin: 0;
  margin-bottom: 5px;
  text-shadow: -3px -3px 7px #495569, 4px 4px 6px #0e1116;
}
.calenderinfo-text {
  display: flex;
  align-items: center;
}
.calenderinfo p {
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  margin: 0;
  position: relative;
  text-shadow: -3px -3px 7px #495569, 4px 4px 6px #0e1116;
}
.calenderinfo p:first-child {
  margin-right: 20px;
}
.calenderinfo p:first-child::after {
  content: "";
  height: 75%;
  width: 1px;
  background-color: #4d535f;
  position: absolute;
  right: -10px;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 1px 0px 0 #000000;
}
.calenderinfo p strong {
  color: #ff9400;
  margin-right: 3px;
}

.calender_body {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.profile-form .form_field_additional_info mat-label,
.form_field_testimonial_content > .ng-star-inserted > mat-label,
.form_field_description > .ng-star-inserted > mat-label {
  background-color: #f8f8f8;
  color: #007a9900;
  margin-left: 10px;
  // border-top: 1px solid #3390a7;
  border-left: 1px solid #a1dceb;
  color: #3390a7;
  border-top: 1px solid #a1dceb;
  border-right: 1px solid #a1dceb;
  padding: 6px;
  border-radius: 4px 4px 0 0;
  font-size: 12px;
  font-weight: 450;
  // font-style: bold;
}

// .form_field_additional_info mat-label {
//   top: 10px!important;
//   color: #107a9b!important;
//   background: #fff;
//   padding: 2px 9px 0 9px;
//   border: 1px solid #a7cfe3;
//   border-bottom: 0;
//   border-radius: 4px 4px 0 0;
// }

.calender_body {
  max-width: inherit !important;
  width: 30% !important;
  margin: 10px 0.5% !important;
  background: #384355;
  padding: 0.5% !important;
  border: solid 1px #445268 !important;
  word-break: break-all;
  transition: all 0.3s ease-in-out;
  box-shadow: -4px -4px 6px #363f51d6, 4px 4px 9px #191e279c;
  position: relative;
  z-index: 0;

  -webkit-animation: scaledown 1s linear;
  -moz-animation: scaledown 1s linear;
  animation: scaledown 1s linear;
  transform-origin: 50% 50%;
  animation-fill-mode: forwards;
}

@keyframes scaledown {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.calender_body .calender_block{ width: 100%; cursor: pointer;}

.calender_body:hover {
  // box-shadow: -10px -10px 13px rgb(19 22 29 / 43%), 14px 16px 16px rgba(0, 0, 0, 0.61);
  // transform: scale(1.1);
  // z-index: 1;

  z-index: 100;
  -webkit-animation: scale 1s linear;
  -moz-animation: scale 1s linear;
  animation: scale 1s linear;
  transform-origin: 50% 50%;
  animation-fill-mode: forwards;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
    -webkit-box-shadow: 10px 10px 60px 10px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 10px 10px 60px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 10px 10px 60px 10px rgba(0, 0, 0, 0.1);
  }
}

.calender_body h2 {
  margin: 0;
  display: block;
  font-size: 18px;
  padding: 8px 8px;
  font-size: 18px;
  line-height: 21px;
  text-transform: capitalize;
  color: #fff;
  margin-bottom: 6px;
  word-break: break-word;
  background: #282f3c;
  border: 1px solid #4d5972;
}

.calender_body p {
  margin: 0;
  padding: 6px 0;
  color: #deeaff;
  display: flex;
  align-items: center;
  font-size: 15px;
}
.available-slots-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.calender_body p .fa {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  font-size: 1.5rem;
  border: 1px solid #37455b;
  box-shadow: -4px -2px 9px #697c9b, 3px 3px 13px #0e1116;
  background: linear-gradient(
    180deg,
    rgb(255, 241, 87) 0,
    rgb(187, 138, 0) 100%
  );
  text-shadow: 1px 1px 1px #f8df53;
  color: #000;
}

.calender_body p strong {
  color: #ff9600;
  font-weight: 500;
  min-width: 90px;
}
.calender_body p strong span {
  width: calc(100% - 140px);
  margin-left: 10px;
}

.calender_body p .innerSpan {
  color: #000;
}

.calender_body .cancelSlotBtnSection {
  margin-top: 10px;
}

.calender_body .slotBookBtn {
  margin: 5px;
}

.calender_body.bookNowModal {
  width: 100% !important;
  background: none;
  box-shadow: none;
  border: none !important;
  word-break: normal;
}

.calender_body.bookNowModal:hover {
  transform: none !important;
}

.Showingh6 {
  font-size: 18px !important;
  color: #fff;
  margin: 0 !important;
  padding: 10px 0 !important;
}

.form_field_wrapperstart_time {
  width: 49.4%;
  float: left;
}

.form_field_wrapperend_time {
  width: 49.4%;
  float: right;
}

.form_field_wrapperstart_time .mat-mdc-form-field-flex {
  position: relative;
  background: #272f3c url(../src/assets/images/timeicon.webp) no-repeat 99%
    center;
  background-size: 25px;
}
.form_field_wrapperstart_time .mat-mdc-form-field-flex input,
.form_field_wrapperend_time .mat-mdc-form-field-flex input {
  cursor: pointer;
}

.form_field_wrapperend_time .mat-mdc-form-field-flex {
  background: #272f3c url(../src/assets/images/timeicon.webp) no-repeat 99%
    center;
  background-size: 25px;
}

.form_field_wrapperdetails {
  clear: both;
  overflow: hidden;
}

// .mat-mdc-dialog-actions button {
//   padding: 2rem 1rem!important;
//   margin: 0 2px;
// }

.eventlist
  .multipledeleteandupdatebuttan
  .mat-mdc-tooltip-trigger:nth-of-type(2) {
  display: none;
}

// .mat-calendar-controls button,
// .mat-calendar-controls button .mat-calendar-arrow {
//   color: #e9f0fc !important;
//   fill: #e9f0fc !important;
// }
.mat-calendar-table-header,
.mat-calendar-body-label {
  color: #495568 !important;
}
.mat-calendar-body-disabled
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  color: #697282 !important;
}
.mat-calendar-body-today:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  border-color: #bdcbe5;
}
.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
 
  border-color: rgba(0, 0, 0, 0);
}
.mat-calendar-body-selected {
  background: var(--orange-gradient) !important;
  background-color: transparent !important;
  color: #000 !important;
  text-shadow: 1px 1px 0 #ffb740;
}
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  background: var(--orange-gradient) !important;
  background-color: transparent !important;
  color: #000 !important;
  text-shadow: 1px 1px 0 #ffb740;
}
.mat-datepicker-content {
  background-color: #3c4659;
}

@media only screen and (max-width: 1300px) {
  .connectedemail h2 {
    font-size: 20px;
  }
  .calenderinfo h4 {
    font-size: 18px;
  }
}
@media only screen and (max-width: 1199px) {
  .addEditFormbooking .grp4 .form_field_wrapper {
    flex: 0 0 49%;
  }

  .addEditFormbooking .form_field_wrapperdaygroup {
    display: block;
  }

  .calender_body {
    padding: 1.5% !important;
    width: 45% !important;
  }
  .available-slots-title {
    justify-content: center;
    flex-direction: column;
  }
  .calenderinfo h4 {
    text-align: center;
  }
}

@media only screen and (max-width: 991px) {
  .calenderinfo-text {
    flex-direction: column;
  }
  .calenderinfo p:first-child {
    margin-right: 0;
    margin-bottom: 5px;
  }
  .calenderinfo p {
    font-size: 15px;
    text-align: center;
  }
  .calenderinfo p:first-child::after {
    display: none;
  }
  .connectedemail h2 {
    font-size: 20px;
    text-align: center;
  }

}

@media only screen and (max-width: 800px) {
  .signup-form .grp3 .form_field_wrapper {
    width: 100%;
  }

  .calender_body {
    width: 99% !important;
  }
}

@media only screen and (max-width: 667px) {
  .signup-form .grp0 .form_field_wrapper {
    width: 100%;
  }

  // .signup-form .grp1 .form_field_wrapper {
  //     width: 49.5%;
  // }

  .addEditFormbooking .grp0,
  .addEditFormbooking .grp2,
  .addEditFormbooking .grp4 {
    display: block;
  }

  .form_field_wrapperstart_time {
    width: auto;
    float: none;
  }

  .form_field_wrapperend_time {
    width: auto;
    float: none;
  }
}

@media only screen and (max-width: 520px) {
  // .signup-form .grp1 .form_field_wrapper {
  //     width: 100%;
  // }
}

// ---------------------------------DEEP SARKAR-----------------------------------\

.right_container .horoceop_form {
  display: flex;
  flex-direction: column;
}

.right_container .horoceop_form .mat-mdc-form-field {
  padding: 5px 10px;
}

.right_container .horoceop_form .mdc-button {
  margin-right: auto;
  margin-left: 10px;
}

// << ---------------- Sanket CSS for Astro ToolKit -------------------- >>

.horoceop_maxi_container {
  display: flex;
  flex-direction: row;
  // overflow: hidden;
  transition: all 0.3s;
}

.horoceop_maxi_container .left_container {
  width: 350px;
  transition: all 0.3s;
}

.horoceop_maxi_container .right_container_compwrp {
  width: calc(100% - 370px);
  margin-left: 20px;
  transition: all 0.3s;
}
.horoceop_maxi_container .menu_hide_style {
  width: 100%;
  margin-left: 20px;
  transition: all 0.3s;
}

.horoceop_maxi_container.hidemenu .left_container {
  margin-left: -350px;
}
.horoceop_maxi_container.hidemenu .right_container_compwrp {
  width: 100%;
  margin-left: 0;
}

.horoceop_maxi_container .left_container .overflow_class ul li:hover {
  background: #435066;
}

.horoceop_maxi_container .left_container .overflow_class ul li.active {
  background: #6c798f;
  color: #000000;
}

.horoceop_maxi_container .list_Hading {
  text-align: center;
  margin: 20px 0;
  background: #232528;
  padding: 1.5rem 2rem;
  box-shadow: 1px 1px 5px rgba(26, 26, 26, 0.3215686275);
  border-radius: 6px;
  border: 1px solid rgba(182, 199, 227, 0.168627451);
}

.horoceop_maxi_container .list_Hading h2 {
  margin: 0;
  font-size: 25px;
  color: #f1f1f1;
}

.horoceop_maxi_container .resulttoggal_button .result_button_wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.horoceop_maxi_container .resulttoggal_button .result_button_wrapper button {
  font-size: 20px;
  margin: 20px 10px;
  height: auto;
  padding: 15px;
}

.horoceop_maxi_container
  .resulttoggal_button
  .result_button_wrapper
  button
  .mdc-button__label {
  display: flex;
  align-items: center;
}

.horoceop_maxi_container
  .resulttoggal_button
  .result_button_wrapper
  button
  .mdc-button__label
  img {
  height: 20px;
  margin-right: 10px;
}

.horoceop_maxi_container
  .right_container_compwrp
  .right_container
  .chart_data_tabile
  .mat-mdc-table
  .mdc-data-table__content
  tr
  td
  img {
  height: 21px;
  margin-right: 5px;
  vertical-align: bottom;
  background: #fff;
  border: 2px solid #fff;
  border-radius: 50%;
}

.horoceop_maxi_container .right_container_compwrp .form_field_wrapper.form_field_wrappertime_zone .mat-mdc-form-field-infix mat-chip-list .mat-mdc-chip , 
.horoceop_maxi_container .right_container_compwrp .form_field_wrapper.form_field_wrapperSelf_time_zone .mat-mdc-form-field-infix mat-chip-list, 
.horoceop_maxi_container .right_container_compwrp .form_field_wrapper.form_field_wrapperS_time_zone .mat-mdc-form-field-infix mat-chip-list .mat-mdc-chip,
.horoceop_maxi_container .right_container_compwrp .form_field_wrapper.form_field_wrapperpartner_time_zone .mat-mdc-form-field-infix mat-chip-list .mat-mdc-chip,
.horoceop_maxi_container .right_container_compwrp .form_field_wrapper.form_field_wrapperP_time_zone .mat-mdc-form-field-infix mat-chip-list .mat-mdc-chip{position: absolute;left:-3px;top: 6px;width: fit-content;}
.horoceop_maxi_container .right_container_compwrp .form_field_wrapper.form_field_wrapperSelf_time_zone .mat-mdc-form-field-infix mat-chip-list .mat-mdc-chip {width: 100%;}



@media only screen and (max-width: 991px) {
  .horoceop_maxi_container .left_container {
    width: 310px;
  }
  .horoceop_maxi_container .right_container_compwrp {
    width: calc(100% - 330px);
  }
}

@media only screen and (max-width: 899px) {
  .horoceop_maxi_container .resulttoggal_button .result_button_wrapper button {
    font-size: 15px;
  }

  .horoceop_maxi_container .resulttoggal_button .result_button_wrapper button {
    padding: 12px;
  }
}

@media only screen and (max-width: 1190px) {
  .horoceop_maxi_container {
    flex-direction: column;
  }
  .horoceop_maxi_container{
    // overflow-y: scroll;
  }

  .horoceop_maxi_container .left_container {
    width: auto;
  }

  .horoceop_maxi_container .right_container_compwrp {
    width: auto;
    margin-left: 0;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 399px) {
  .horoceop_maxi_container .addEditForm {
    margin-bottom: 12px;
  }

  .horoceop_maxi_container .resulttoggal_button .result_button_wrapper button {
    margin: 5px;
  }
}

// << ---------------- Sanket CSS for Astro ToolKit -------------------- >>

.addBtnDiv {
  display: inline-block !important;
  background: var(--green-gradient) !important;
  text-shadow: 1px 1px 1px #c7f353 !important;
  text-shadow: 1px 1px 1px #494949 !important;
  color: var(--white) !important;
  font-size: 2rem !important;
  text-transform: uppercase !important;
  min-width: 264px !important;
  margin: 5px !important;
  padding: 1rem 2rem !important;
  line-height: 2.5rem !important;
  height: 50px !important;
  margin-bottom: 10px !important;
}
.addBtnDiv.hide {
  display: none !important;
}

.newbtngroup {
  display: block;
  text-align: center;
}

.modaldemotext {
  margin: 0 !important;
  padding: 10px;
  font-size: 2.4rem !important;
  text-transform: uppercase;
  background: var(--gray-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.grp2ndCon {
  font-size: 0px;
}

div.mat-mdc-autocomplete-panel {
  background-color: #181414 !important;
}

.form_field_customer_name mat-chip-list {
  position: absolute;
  left: 1px;
  top: 7px;
}

.form_field_customer_name mat-chip-list mat-chip{
  padding: 0 10px;
}

.showcasespreadpage .form-group input.cdk-text-field-autofill-monitored{ font-size: 0px;}

.showcasespreadpage .form-group input.cdk-text-field-autofill-monitored:focus{ font-size: 16px;}

 

.subwrapper.tarotreading_listwrap mat-form-field mat-chip-list mat-chip {
  padding: 0 10px;
  margin: 0 !important;
  position: absolute;
  right: 0;
  top: 11px;
}

.showcasefrom .submitbtnsection{display: none;}
.showcasefrom .visibleBtn{display: flex;}

.reslist_html .result_data_html {
  margin: 20px 0px;
  display: block;
  background: #282f3c;
  border-radius: 5px;
  color: white;
  padding: 20px;
  overflow-y: auto;
}

.custom-modalboxNew {
  width: min(90%, 1000px) !important;
}


.available-slots .submitform .mdc-text-field {
  border-radius: 4px;
  background: #272f3c;
  border: 1px solid #333d4e;
  box-shadow: inset -3px -4px 2px #4e5e78b3, inset 2px 4px 9px #000000a8;
}

.available-slots .mdc-fab{ width: 45px; height: 45px; margin: 4px;}

 

// .booked-slots td span strong{ display: inline-block; padding-right: 10px;}


.cencelbutton button::after{content:"\f05c"}
.reschedulebutton button::after{content:"\f079"}

.cencelbutton button,.reschedulebutton button{ font-size: 0px;}


.cencelbutton .mat-mdc-raised-button.mat-primary,.reschedulebutton .mat-mdc-raised-button.mat-primary{ background: none; box-shadow: none;     height: auto; min-width: inherit;     padding: 0 5px;}

.cencelbutton span,.reschedulebutton span{ height: auto; min-width: inherit;}

 
body.hidescroll{overflow: hidden;}





.bookmark-sub-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 110px;
  background: #272f3c;
  position: fixed;
  right: -200px;
  top: 220px;
  z-index: 999;
  border: 10px solid #3f4b5e;
  // display: none;
  transition: all 0.3s;

}
.bookmark-sub-wrapper p{
  color: #ff4100;
  margin: 20px;
  font-size: 15px;
}
.bookmark-sub-wrapper.show{right: 0;}
.bookmark-menu {
  width: calc(55px - 6px);
    margin: 2px;
    display: flex;
    justify-content: center;
    border: 1px solid #343f50;
}
.bookmark-menu a {
  display: block;
  width: 50px;
  text-align: center;
  cursor: pointer;
  padding: 5px;
}
.bookmark-menu a:hover, .bookmark-menu a.active{
  background: #171b22;
}
.bookmark-menu a img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}
.bookmark-iconwrapper .fa{
font-size: 25px;
}
.bookmark-iconwrapper {
  background: var(--orange-gradient);
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: fixed;
  right: 0;
  top: 170px;
  color: #fff;
  text-shadow: 2px 2px 1px #b76002;
  z-index: 999;
  border-radius: 5px 0 0 5px;
}
.loader .mat-mdc-progress-spinner{
  width: 30px !important;
  height: 30px !important;
}
.loader .mat-mdc-progress-spinner{    --mdc-circular-progress-active-indicator-color: #ff8800;}
// .loader {
//   position: absolute;
//   left: 0;
//   top: 0;
//   width: 100%;
//   height: 100%;
//   background: #0c0e13e7;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

.assignmentnextbtn, .assignmentsubmitbtn{
  background: var(--orange-gradient);
  box-shadow: 0 5px 15px rgba(242, 97, 103, 0.4);
  color: var(--white);
  font-weight: 600;
  border-radius: 5rem;
  margin: 0 auto;
  padding: 1rem 2rem;
  font-size: var(--base-font-size20);
  display: block;
  cursor: pointer;
  border: none;
}
.assignment-modal .mat-mdc-form-field{

}

.assignment-modal .mat-mdc-form-field-flex {
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0 10px;
  height: 50px;
  background: #272f3c;
  border: 1px solid #333d4e;
  box-shadow: inset -3px -4px 2px #4e5e78b3, inset 2px 4px 9px #000000a8;
}
.assignment-modal .textareainput .mat-mdc-form-field-flex textarea {
  height: 116px;
  resize: none;
  color: #d8e6ff;
}
.assignment-modal .textareainput .mat-mdc-form-field-flex {
  height: 150px;
}
.assignment-modal .form_field_wrapper .mat-mdc-form-field {
  width: 100%;
}
.assignment-modal .mat-mdc-text-field-wrapper {
  width: 100%;
  padding: 0;
  background-color: transparent !important;
}
.assignment-modal .assign-title{
  background: #c9d4e9;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.assignment-modal .mat-mdc-form-field{margin-bottom: 10px;width: 100%; }
.assignment-modal .assign-title p{ 
  margin: 0;
  font-size: 18px;
}

.assign-subwrapper {
  background: #363e4e;
  padding: 8px;
  border-radius: 5px;
  margin-bottom: 8px;
  border: 1px solid #566176;
}
.assign-subwrapper h2 {
  color: #ff9d0f;
  margin: 0;
  border-bottom: 1px solid #4e5971;
  margin-bottom: 5px;
  padding-bottom: 0px;
  font-size: 18px;
}
.assign-subwrapper p {
  font-size: 14px;
  margin: 0;
  color: #bfcce5;
}




// -------------------Blog-Management-Modal-CSS-Start-------------------
// .blog_modal_main_width {
//   padding: 15px;
// }
.blog_mdl_heading_sec {
  background: #384355;
  border: 1px solid #4a5870;
  border-radius: 5px;
  padding: 1.5rem 48px;
  margin-bottom: 15px;
}
.blog_mdl_heading_sec h1 {
  font-size: 2.4rem;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 1px 1px 1px #053650;
  margin: 0;
}
.blog_mdl_data_sec_main {
  padding: 0 10px;
}
.blog_data_sec {
  background: #384355;
  border: 1px solid #51617c;
  border-radius: 3px;
  padding: 10px !important;
  box-shadow: none !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 6px;
}
.blog_data_sec.cng_bg {
  background: #505f78;
  border: 1px solid #6c80a1;
}
.blog_data_sec h5 {
  width: 20%;
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
  color: #ffffff;
  text-wrap: balance;
  margin: 0;
}
.blog_data_sec p {
  width: 78%;
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  color: #ffffff;
  text-wrap: balance;
  margin: 0;
}
// -------------------Blog-Management-Modal-CSS-End-------------------


// ----------------VIDEO-PLAY-MODAL-CSS-START------------------
.video_play_modal_main .custom-modalbox {
  width: min(90%, 900px) !important;
  max-width: inherit !important;
  max-height: 94vh !important;
}
.video_play_modal_main .custom-modalboxNew {
  width: min(90%, 900px) !important;
  max-width: inherit !important;
  max-height: 94vh !important;
}
.video_play_modal_main iframe {
  width: 100%;
  min-height: 300px;
}
.video_play_modal_main .modal-youtube-video video {
  width: 100%;
}
// ----------------VIDEO-PLAY-MODAL-CSS-END------------------


// ----------------VIDEO-ADD-COMPONENT-MODAL-CSS-START------------------
.video_add_component_main_width .videoshowblock {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
}
.video_add_component_main_width .video-Sub-block {
  background: #1e2631;
  padding: 15px;
  border-radius: 5px;
  margin: 5px;
  border: 1px solid #adb8b2;
  width: calc(20% - 52px);
  position: relative;
}
.video_add_component_main_width .button-wrapper {
  position: absolute;
  right: 6px;
  top: 0;
  display: flex;
  z-index: 9;
}
.video_add_component_main_width .button-wrapper a {
  height: 35px;
  width: 35px;
  background: linear-gradient(#ffa800, #ff7e00);
  margin: 0 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
}
.video_add_component_main_width .video-th-image {
  position: relative;
  padding: 6px;
  background: #384355;
  margin-bottom: 8px;
}
.video_add_component_main_width .video-th-image img.playicon {
  width: 60px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #ddd;
  border-radius: 50%;
  height: 60px;
  border: 1px solid #fff;
}
.video_add_component_main_width .video-th-image img {
  max-width: 100%;
  display: block;
  width: 100%;
  margin: 0 auto;
  cursor: pointer;
}
.video_add_component_main_width .videos p {
  background: #384355;
  padding: 6px;
  font-size: 17px;
  line-height: 22px;
  color: #ebebeb;
  text-align: start;
}
.video_add_component_main_width .videos p:last-child {
  margin-bottom: 0;
}

.video_add_modal_main_width .form_field_wrappervideotype {
  flex: 1 0 90.7%;
}
.video_add_modal_main_width .form_field_videofile mat-label {
  display: block;
  font-size: 2.4rem;
  text-align: center;
  margin-bottom: 2rem;
}
.video_add_modal_main_width .form_field_videofile mat-label {
  padding: 10px 35px;
  margin: 20px auto;
  background-color: #608293;
  color: #fff;
  border-radius: 25px;
}
.video_add_modal_main_width .form_field_wrappervideofile .drop {
  height: inherit !important;
  width: inherit !important;
  padding: 10px 20px;
  cursor: pointer;
  background: var(--blue-gradient) !important;
}
// ----------------VIDEO-ADD-COMPONENT-MODAL-CSS-END------------------


// ---------------COMMON-PREVIES-MODAL-CSS-START-------------------

.cmn_preview_modal_main_width .PreviewModalBoxCon div span {
  width: 50%;
}
.cmn_preview_modal_main_width .PreviewModalBoxCon div .img_sec_main {
  width: 100%;
}
.cmn_preview_modal_main_width .PreviewModalBoxCon div img {
  display: block;
  max-width: 100%;
  border: 1px solid #51617c
}
.cmn_preview_modal_main_width .PreviewModalBoxCon div {
  justify-content: space-between;
}
.cmn_preview_modal_main_width audio {
  width: 90%;
  display: block;
  margin: 0 auto;
}

// ---------------COMMON-PREVIES-MODAL-CSS-END-------------------



//  deep 
.video_sec iframe {
  display: block;
  width: 100%;
}
.video_sec video{
  display: block;
  width: 100%;
}
.social_media_sec_wrpr {
  display: flex;
  justify-content: space-between;
}
.video_sec_main_box .video_box_main {
  width: calc(100% - 60px);
}
.video_sec_main_box .video_sec {
  width: calc(100% - 10px);
  height: 680px;
}
.video_sec_main_box .video_sec iframe {
  height: 100%;
}



// -----------CONVERSION-REPORT-MODAL-CSS-START-------------

.conversion_report_modal_full_width {
  width: 1265px !important;
}
.conversion_report_modal_full_width .mat-mdc-raised-button:not(:disabled) {
  background-color: #00000000 !important;
  box-shadow: unset;
}
.conversion_report_modal_full_width .mdc-button {
  min-width: auto !important;
}


@media only screen and (max-width: 1380px) {
  .conversion_report_modal_full_width {
    width: 90% !important;
  }
}
// -----------CONVERSION-REPORT-MODAL-CSS-END-------------




// -----------CLICK-REPORT-MODAL-CSS-START-------------

.click_report_modal_full_width {
  width: 1265px !important;
}
.click_report_modal_full_width .mat-mdc-raised-button:not(:disabled) {
  background-color: #00000000 !important;
  box-shadow: unset;
}


@media only screen and (max-width: 1380px) {
  .click_report_modal_full_width {
    width: 90% !important;
  }
}
// -----------CLICK-REPORT-MODAL-CSS-END-------------